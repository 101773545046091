<template>
  <div class="home">
    <div class="banner pc-banner">
      <swiper class="swiperbanner" ref="mySwiper" :key="updateKey" :options="swiperList">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img :src="item.title_img" />
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-header" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-prev-header" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-next-header" slot="button-next"></div>
      </swiper>
    </div>
    <!-- 关于我们 -->
    <div class="home-about">
      <div class="about-main">
        <div class="header">关于我们</div>
        <div class="title">广州市农药肥料应用协会，成立于2016年，经广州市社会组织管理局批准成立，协会受广东省农农业农村厅、广州市农业农村局领导。</div>
        <div class="main">
          <div class="main-block main-left">
            <div class="block-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/2f053088ca814b21ad7df40dc97b488b.png"
                alt="">
            </div>
            <div class="block-text">
              <div class="block-header">服务范围</div>
              <div class="block-title">广东省粤港澳大湾区</div>
            </div>
          </div>
          <div class="main-block main-right">
            <div class="block-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/a66fa0f0aea04e1da079e05b2c6dc434.png"
                alt="">
            </div>
            <div class="block-text">
              <div class="block-header">服务对象</div>
              <div class="block-title title-right">广大农药、肥料生产经营单位</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 协会服务 -->
    <div class="serve">
      <div class="serve-title">协会服务</div>
      <div class="serve-main">
        <div class="main main-one">
          <div class="main-img">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/f5d781e30af8490ea5769a0dc76d155e.png"
              alt="">
          </div>
          <div class="block_right">
            <div class="main-title">一体化管理</div>
              <div class="main-text">
                <p>农资实体店+小程序店，</p>
                <p>软/硬件专业配套服务</p>
              </div>
          </div>
        </div>
        <div class="main main-two">
          <div class="main-img ">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/4d2f0d889a3b4bd3be468a809b5de774.png"
              alt="">
          </div>
          <div class="block_right">
            <div class="main-title">数字化服务</div>
            <div class="main-text" v-if="windowWidth > 768">
              <p>农资实体店，进销存收银</p>
              <p>台账数据自动采集，实现</p>
              <p>数字化升级服务</p>
            </div>
            <div class="main-text" v-else>农资实体店，进销存收银台账数据自动采集，实现数字化升级服务
            </div>
          </div>
        </div>
        <div class="main main-three">
          <div class="main-img">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/90ca6a9c055943f6baef1007b3909edc.png"
              alt="">
          </div>
          <div class="block_right">
            <div class="main-title">证件办理</div>
            <div class="main-text" v-if="windowWidth > 768">
              <p>协助办理农药经营证件，</p>
              <p>药害、肥害纠纷代理鉴定</p>
            </div>
            <div class="main-text" v-else>协助办理农药经营证件，药害、肥害纠纷代理鉴定
            </div>

          </div>
        </div>
        <div class="main main-four">
          <div class="main-img">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ae13e264748242e79d42ee2bc56aca2d.png"
              alt="">
          </div>
          <div class="block_right">
            <div class="main-title">纠纷协调</div>
            <div class="main-text">
              <p>药害肥害纠纷协调处理</p>
            </div>
          </div>
        </div>
        <div class="main main-five">
          <div class="main-img">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/4c580d79fe7b46b38603fe984080288f.png"
              alt="">
          </div>
          <div class="block_right">
            <div class="main-title">咨询服务</div>
            <div class="main-text" v-if="windowWidth > 768">
              <p>《农药经营许可证》咨询</p>
              <p>服务</p>
            </div>
            <div class="main-text" v-else>《农药经营许可证》咨询服务
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 最新动态 -->
    <div class="dynamic-title">
      <div class="text">
        最新动态
      </div>
      <div class="swiper" v-if="windowWidth > 768">
        <div class="achievements" v-if="UseVideosList.length">
          <swiper class="swiper" ref="mySwiper" :key="updateKey" :options="swiperOptionOther">
            <swiper-slide class="achievements-list" v-for="(item, index) in UseVideosList" :key="index">
              <div class="achievements-item" @click="getDetail(item)">
                <div class="img">
                  <img :src="item.title_image" />
                </div>
                <div class="certificate" >{{ item.title }}</div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <div class="line" style="padding: 0 10px">
            <div v-if="$isMobile" style="border-bottom: 1px solid #f1f1f1; margin-top: 5px"></div>
          </div>
        </div>
      </div>
      <!-- 手机版 -->
      <div class="dynamic" v-else>
        <div class="dynamic-main">
          <div class="main_item" v-for="(item,index) in UseVideosList" :key="index" @click="getDetail(item)">
            <img :src="item.title_image" alt="">
            <div class="item_title">{{ item.title }}</div>
          </div>
        </div>
        <div class="more" @click="goMore" v-if="isShowMore">
          加载更多
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240918/17159981748505699804" alt="">
        </div>
        <div v-else class="more"></div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getArticleList, getBannerList } from '@/api/admin'

// import 'swiper/css'

export default {
  name: 'Home',
  components: {
  },
  computed: {
    ...mapGetters(['IS_LARGE_SCREEN'])
  },
  data () {
    return {
      // 广告轮播
      bannerList: [],
      swiperList: {
        observer: true,
        slidesPerView: 1,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination-header',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next-header',
          prevEl: '.swiper-button-prev-header'
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      },
      UseVideosList: [],
      updateKey: +new Date(),
      swiperOptionOther: {
        observer: true,
        slidesPerView: 3,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      },
      reqParams: {
        page: 1,
        per_page: 4,
        search_data: '', // 关键字搜索
        advisory_type: '2', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      windowWidth: 1024,
      isShowMore: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizePage)
    this.resetBannerNum()
    this.getList()// 获取数据
    this.getbannerList()
    this.windowWidth = window.document.body.clientWidth
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    goMore () {
      this.reqParams.page++
      this.getList()
    },
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
      this.windowWidth = window.document.body.clientWidth
    },
    // 获取 最新动态 的视频列表
    async getList () {
      const { list } = await getArticleList(this.reqParams)
      console.log(list.length, this.reqParams.per_page)
      if (list.length < this.reqParams.per_page) {
        this.isShowMore = false
      }
      if (this.reqParams.page === 1) {
        this.UseVideosList = list || []
      } else {
        this.UseVideosList = [...this.UseVideosList, ...list]
      }
      console.log(this.UseVideosList, 'smdx')
    },
    getbannerList () {
      const obj = {
        page: 1,
        per_page: 200
      }
      getBannerList(obj).then(res => {
        console.log('banner', res)
        let list = []
        let list2 = []
        for (let i = 0; i < res.length; i++) {
          if (res[i].title === '首页轮播图（PC版）') {
            list = res[i].list
          } else if (res[i].title === '首页轮播图（手机版）') {
            list2 = res[i].list
          }
        }
        if (window.document.body.clientWidth > 768) {
          this.bannerList = list
        } else {
          this.bannerList = list2
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 最新动态 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 900) {
        this.swiperOptionOther.slidesPerView = 3
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOptionOther.slidesPerView = 2
      } else {
        this.swiperOptionOther.slidesPerView = 1
      }
      this.updateKey = +new Date()
    },
    // 跳到详情
    getDetail (item) {
      this.$router.push({
        name: 'video',
        query: {
          title: item.title,
          advisory_type: item.advisory_type,
          type: 'video'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: -15px !important;
  }
.home {
  width: 100%;
  height: 100%;
  background-color: #f9fbff;
}

.banner {
  margin-top: 68px;
  height: 628px;
  width: 100%;
  background-size: cover;
  .swiperbanner{
    width: 100%;
    height: 628px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

// 关于我们 css
.home-about {
  .about-main {
    width: 1200px;
    height: 322px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 58px;
    padding-top: 36px;

    .header {
      width: 128px;
      height: 45px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #333333;
      line-height: 38px;
      margin: 0 auto;
    }

    .title {
      width: 951px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: center;
      margin: 0 auto;
      color: #333333;
      line-height: 19px;
      margin-top: 8px;
    }

    .main {
      display: flex;

      .main-left {
        margin-left: 68px;
        margin-right: 19px;
      }

      .main-right {
        margin-left: 19px;
        margin-right: 80px;
      }

      .main-block {
        width: 507px;
        height: 112px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 0px 15.5px 4px rgba(51, 51, 51, 0.04);
        display: flex;
        align-items: center;
        margin-top: 46px;

        .block-img {
          display: flex;
          align-items: center;
          margin-left: 71px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .block-header {
          margin-left: 32px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }

        .block-title {
          margin-top: 9px;
          margin-left: 32px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }
}

.serve {
  height: 432px;

  .serve-title {
    width: 1200px;
    font-size: 32px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    color: #333333;
    line-height: 38px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 33px;

  }

  .serve-main {
    width: 1200px;
    margin: 0 auto;
    display: flex;

    // justify-content: space-between;
    .main-one {
      margin-left: 26px;
    }

    .main-two {
      width: 176px;
      margin-left: 71px;
    }

    .main-three {
      width: 176px;
      margin-left: 68px;
    }

    .main-four {
      width: 160px;
      margin-left: 71px;
    }

    .main-five {
      width: 176px;
      margin-left: 81px;
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;

      // width: 170px;
      .main-img {
        width: 113px;
        height: 113px;
      }

      .main-title {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-top: 26px;
      }

      .main-text {
        // height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        white-space: nowrap;
        margin-top: 16px;
      }
    }
  }
}

.dynamic-title {
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;

  .text {
    width: 1200px;
    margin: 0 auto;
    font-size: 32px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    color: #333333;
    line-height: 38px;
    text-align: center;
    padding-top: 62px;

  }
}

.swiper {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 95px;
  .achievements {
    width: 1200px;
    margin: 0 auto;
    margin-top: 33px;
    .achievements-list {
      box-sizing: border-box;
      height: 374px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 0px 15.5px 4px rgba(51,51,51,0.04);
      .achievements-item {
        height: 246px;
        box-sizing: border-box;
        .img {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .certificate {
          padding: 19px 30px 0 30px ;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .achievements .swiper-pagination {
  .swiper-pagination-bullet {
    margin: 0px 5px;
    background: hsl(0, 2%, 71%);
  }

  .swiper-pagination-bullet-active {
    background-color: #a3a3a3;
  }
}
.swiper-button-prev-header,
.swiper-button-next-header,
.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 75px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  /* 使用半透明色遮罩 */
  top: 50%;
  margin-top: -90px;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}
.swiper-button-prev-header {
  left: 0;
}

.swiper-button-next-header {
  right: 0;
}
</style>

<style scoped lang='scss'>
@import "./home-media"; // 手机端适配样式</style>
</style>
